<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    ransition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        small
        @click="doClick"
      >
        {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog:false,
    }
  },
  watch:{
    closed(v){
      console.log(v)
      if(v !== undefined) this.dialog = !v
    },
    dialog(v){
      if(this.closed !== undefined){
        this.$emit("update:closed",!v)
      }
    }
  },
  methods:{
    Open(){
      this.dialog = true
      this.doClick()
    },
    doClick(){
      // console.log(typeof(this.callback))
      if(this.callback !== undefined && typeof(this.callback) === 'function') this.callback()
    }
  },
  props: ["text","title","closed","callback"],
}
</script>

<style>

</style>