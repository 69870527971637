<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm nhân viên</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="submitForm(submitData)"
              :disabled="!valid"
            >
              Thêm
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          two-line
          subheader
        >
          <v-subheader>Nhóm nhân viên</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-select 
                  v-model="submitData.Permision"
                  :items="Type"
                  item-value="code"
                  item-text="text"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          subheader
        >
          <v-subheader>Thông tin</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-text-field 
                    v-model="submitData.Name"
                    label="Tên"
                    :rules="[required('Tên')]"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field 
                    v-model="submitData.Username"
                    label="Tài khoản"
                    :rules="[required('Username')]"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field 
                    v-model="submitData.Password"
                    label="Password"
                    :rules="[required('Password')]"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
import {genShipmentName} from '@/plugins/helper/string'
export default {
  data () {
    return {
      valid:false,
      dialog: false,
      submitData:{
        Permision:'warehouse',
        Name: '',
        Username: '',
        Password: '',
      },
      Type: [
        {code:'warehouse',text:'Kho'},
        {code:'sale',text:'Sale'},
        {code:'admin',text:'Admin'},
        {code:'manager',text:'Quản lý'},
        {code:'checker',text:'Kiểm hàng'},
        {code:'warehousevn',text:'Kho VN'},
        {code:'accounting',text:'Kế toán'},
        {code:'ctv',text:'Cộng tác viên'},
      ]
    }
  },
  computed:{
    getPrefix(){
      return genShipmentName(this.submitData.Type)
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    }
  },
  methods: {
    ...validations,
  },
  mounted(){
    if(this.perm === 'manager'){
      this.Type.splice(2,2);
      
    }
  },
  props: ["enable","submitForm","perm"]
}
</script>

<style>

</style>