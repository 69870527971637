export const shipment = {
  "dang_xu_ly":{
    code:"dang_xu_ly",
    text:"Đang xử lý",
    color:"green"
  },
  "dang_kiem_tra":{
    code:"dang_kiem_tra",
    text:"Đang kiểm tra",
    color:"blue"
  },
  "khoa":{
    code:"khoa",
    text:"Đã khóa",
    color:"orange"
  },
}
export const staff = {
  "admin":{
    code:"admin",
    text:"Admin",
    color:"red"
  },
  "manager":{
    code:"manager",
    text:"Quản lí",
    color:"indigo"
  },
  "warehouse":{
    code:"warehouse",
    text:"Kho hàng",
    color:"blue"
  },
  "warehousevn":{
    code:"warehousevn",
    text:"Kho hàng VN",
    color:"blue"
  },
  "sale":{
    code:"sale",
    text:"Sale",
    color:"green"
  },
  "checker":{
    code:"checker",
    text:"Kiểm hàng",
    color:"orange"
  },
  "accounting":{
    code:"accounting",
    text:"Kế toán",
    color:"green darken-2"
  },
  "ctv":{
    code:"ctv",
    text:"Cộng tác viên",
    color:"blue darken-2"
  },
}
export const payment = {
  "cho_thanh_toan":{
    code:"cho_thanh_toan",
    text:"Chờ thanh toán",
    color:"red"
  },
  "dang_xu_ly":{
    code:"dang_xu_ly",
    text:"Đang xử lí",
    color:"blue lighten-1"
  },
  "moi_thanh_toan":{
    code:"moi_thanh_toan",
    text:"Mới thanh toán",
    color:"amber lighten-1"
  },
  "da_thanh_toan":{
    code:"da_thanh_toan",
    text:"Đã thanh toán",
    color:"green"
  },
  "da_nhan_tai_kho":{
    code:"da_nhan_tai_kho",
    text:"Đã nhận hàng tại kho",
    color:"green"
  },
}
export const shipmentStatus = {
  "kho":{
    code:"kho",
    text:"Kiểm hoá tại kho TomExpress",
    color:"blue"
  },
  "quoc_te":{
    code:"quoc_te",
    text:"Đang vận chuyển quốc tế từ Narita",
    color:"amber lighten-1"
  },
  "quoc_te2":{
    code:"quoc_te2",
    text:"Đang vận chuyển quốc tế từ cảng Yokohama",
    color:"amber lighten-1"
  },
  "vietnam":{
    code:"vietnam",
    text:"Cập cảng quốc tế Nội Bài chờ phát",
    color:"green"
  },
  "vietnam2":{
    code:"vietnam2",
    text:"Cập cảng quốc tế HCM chờ phát",
    color:"green"
  },
}
export const documentHawb = {
  "bill_bay":{
    code:"bill_bay",
    text:"Bill Bay",
  },
  "gpxk":{
    code:"gpxk",
    text:"Giấy phép XK",
  },
  "invoice":{
    code:"invoice",
    text:"Invoice",
  },
  "hoa_don":{
    code:"hoa_don",
    text:"Hoá đơn",
  },
}
export const YahooAccount = {
  "cho_xac_nhan":{
    code:"cho_xac_nhan",
    text:"Chờ xác nhận",
    color:"blue",
  },
  "dang_hoat_dong":{
    code:"dang_hoat_dong",
    text:"Hoạt động",
    color:"green",
  },
  "dang_loi":{
    code:"dang_loi",
    text:"Đang lỗi",
    color:"red",
  },
  "han_che":{
    code:"han_che",
    text:"Tài khoản bị hạn chế",
    color:"orange",
  },
  "sai_pass":{
    code:"sai_pass",
    text:"Tài khoản bị sai mật khẩu",
    color:"orange",
  },
  "su_co":{
    code:"su_co",
    text:"Tài khoản gặp sự cố",
    color:"red",
  },
  "da_xoa":{
    code:"da_xoa",
    text:"Tài khoản bị loại bỏ",
    color:"red",
  },
  "tam_ngung":{
    code:"tam_ngung",
    text:"Tài khoản đang tạm ngừng",
    color:"red",
  },
}