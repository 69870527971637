<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách nhân sự</h3>
                <v-spacer />
                <v-btn color="green" dark @click="boxAdd = true" v-if="loginInfo._perm === 'admin'">Thêm</v-btn>
              </v-toolbar>
              <v-card-title>
                <v-row>
									<v-col
										cols="auto"
										v-if="loginInfo._perm === 'admin'"
									>
										<Dialog :text="Selected.length > 0 ? `Đổi thông tin ${Selected.length} NV`:`Đổi thông tin tất cả NV`" title="Đổi thông tin nhanh" :closed="closed">
											<v-form v-model="valid">
												<v-list>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title>% Phí mua hàng</v-list-item-title>
															<v-list-item-subtitle>
																<v-text-field
																	v-model="UpdateData['AuctionFee']"
																	label="Phí mua hàng"
																	hint="Để trống để không đổi !!!"
																	persistent-hint
																/>
															</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title>Tỉ giá JPY</v-list-item-title>
															<v-list-item-subtitle>
																<v-currency-field
																	v-model="UpdateData['JPY']"
																	label="Tỉ giá JPY"
																	:rules="[required('Tỉ giá JPY')]"
																/>
															</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-action>
																<v-btn
																	color="green"
																	dark
																	@click="doUpdateAuction()"
																	:disabled="!valid"
																>
																	Đổi thông tin
																</v-btn>
															</v-list-item-action>
														</v-list-item-content>
													</v-list-item>
												</v-list>
											</v-form>
										</Dialog>
									</v-col>
                  <v-col
                    cols="auto">
                    <v-checkbox
                        v-model="Locked"
                        :label="Locked ? 'Tài khoản khóa':'Tài khoản thường'"
                        @change="LayDuLieu()"
                        color="green"
                    />
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
								v-model="Selected"
                :loading="loading"
                :headers="headers"
                :items="DanhSachMoi"
                :search="search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
								:show-select="loginInfo._perm == 'admin' ? true:false"
								item-key="_id"
							>
								<template v-slot:[`header.data-table-select`]="{ item }">

								</template>
                <template v-slot:[`item.Username`]="{ item }">
                  <Dialog :text="item.Username" title="Đổi mật khẩu" :closed="closed" v-if="item.Permision !=='admin'">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title><h3>Mật khẩu mới</h3></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-form v-model="valid">
                            <v-row>
                              <v-col
                                cols="6"
                              >
                                <v-text-field 
                                  v-model="NewPassword"
                                  label="Mật khẩu mới"
                                  :rules="[minLength('Mật khẩu',6)]"
                                />
                              </v-col>
                              <v-col cols="6">
                                <v-btn :disabled="!valid" color="green" dark @click="CapNhatMatKhau(item._id)" v-if="loginInfo._perm === 'admin'">
                                  Đổi
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </Dialog>
                  <template v-else>
                    {{ item.Username }}
                  </template>
                  <br />
                  <v-btn
                    dark
                    color="orange"
                    v-if="loginInfo._perm === 'admin' && item.Permision !== 'admin'"
                    class="ma-2"
                    x-small
                    @click="doChangeAccountStatus(item)"
                  >
                    {{item.Locked ? 'Mở khóa TK':'Khóa tài khoản'}}
                  </v-btn>
                </template>
                <template v-slot:[`item.Permision`]="{ item }">
                    <v-btn text :color="status[item.Permision].color">{{ status[item.Permision].text }}</v-btn>
                </template>
								<template v-slot:[`item.PartnerData`]="{ item }">
                  <template v-if="item.Permision !== 'ctv'">
                    <template v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale'">
                      <v-btn
                        color="blue"
                        small
                        @click="toggleDoiCuoc(item,'doifee')"
                        dark
                      >
                        Đổi cước
                      </v-btn>
                    </template>
                    <p><span class="text-body blue--text">Phí mua hàng: {{item.AuctionFee}}%</span></p>
                    <p><span class="text-body blue--text">Tỉ giá: {{DoiCuoc(1,false)}} = {{DoiCuoc(item.JPY)}}</span></p>
                  </template>
								</template>
                <template v-slot:[`item.Shipment`]="{ item }">
                  <template v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale'">
                    <v-btn
                      color="blue"
                      small
                      @click="toggleDoiCuoc(item)"
                      dark
                    >
                      Đổi cước
                    </v-btn>
                  </template>
                    <p><span class="text-body blue--text">Air: {{DoiCuoc(item.Shipment)}}</span></p>
                    <p><span class="text-body blue--text">Sea: {{DoiCuoc(item.ShipmentSea)}}</span></p>
                </template>
                <template v-slot:[`item.ChietKhau`]="{ item }">
                  <template v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale'">
                    <v-btn
                        color="blue"
                        small
                        @click="toggleDoiCuoc(item,'doick')"
                        dark
                    >
                      Đổi chiết khấu
                    </v-btn>
                  </template>
                  <p><span class="text-body blue--text">CKA: {{item.CKA}}%</span></p>
                  <p><span class="text-body blue--text">CKB: {{item.CKB}}%</span></p>
                </template>
								<template v-slot:[`item.Rules`]="{ item }">
									<v-checkbox
										class="mx-12"
										:input-value="item.Limited"
										label="Giới hạn"
										@change="doUpdateLimited(item)"
										v-if="item.Permision === 'sale'"
									/>
									<v-checkbox
										class="mx-12"
										:input-value="item.Rules.Partner && !item.Limited"
										label="Đối tác"
										@change="doUpdateRules(item,'Partner')"
										v-if="item.Permision === 'sale'"
										:disabled="item.Limited"
									/>
									<v-checkbox
										class="mx-12"
										:input-value="item.Rules.Auction && !item.Limited"
										label="Đấu giá"
										@change="doUpdateRules(item,'Auction')"
										v-if="item.Permision === 'sale'"
										:disabled="item.Limited"
									/>
								</template>
								<template v-slot:[`item.TopSale`]="{ item }">
									<v-select
										v-model="item.TopSale"
										:items="ListSale"
										item-value="_id"
										item-text="Username"
										v-if="item.Permision === 'sale' || item.Permision === 'ctv'"
										:disabled="loading"
										@change="doUpdateTopSale(item)"
									/>
								</template>
                <template v-slot:[`item._id`]="{ item }">
                    <Manage :item="item" :controls="CustomControls(item)" />
                </template>
              </v-data-table>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                />
              </v-overlay>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <Add :enable.sync="boxAdd" :submitForm="Them" :perm="loginInfo._perm" />
    <v-dialog
      v-model="boxCuoc"
      persistent
      max-width="600px"
    >
    <v-form v-model="valid">
      <v-card>
        <v-card-title>{{FeeData.Title}}</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h3>Thông Tin</h3></v-list-item-title>
              <v-list-item-subtitle>
                  <v-row>
                    <template v-if="FeeData.Query === 'doick'">
                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            v-model="FeeData.Body.CKA"
                            label="Chiết khấu % A"
                            :rules="[required('Chiết khấu % A')]"
                        />
                      </v-col>
                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            v-model="FeeData.Body.CKB"
                            label="Chiết khấu % B"
                            :rules="[required('Chiết khấu % B')]"
                        />
                      </v-col>
                    </template>
										<template v-if="FeeData.Query === 'doicuoc'">
											<v-col
												cols="6"
											>
												<v-currency-field
													v-model="FeeData.Body.Shipment"
													label="Cước Air"
													:rules="[required('Cước Air')]"
												/>
											</v-col>
											<v-col
												cols="6"
											>
												<v-currency-field
													v-model="FeeData.Body.ShipmentSea"
													label="Cước Sea"
													:rules="[required('Cước Sea')]"
												/>
											</v-col>
										</template>
										<template v-if="FeeData.Query === 'doifee'">
											<v-col
												cols="6"
											>
												<v-text-field
													v-model="FeeData.Body.AuctionFee"
													label="% Phí mua hàng"
													:rules="[required('Phí mua hàng')]"
												/>
											</v-col>
											<v-col
												cols="6"
											>
												<v-currency-field
													v-model="FeeData.Body.JPY"
													label="Tỉ giá JPY"
													:rules="[required('Tỉ giá JPY')]"
												/>
											</v-col>
										</template>
                  </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            @click="boxCuoc = false"
          >
            Đóng
          </v-btn>
          <v-spacer />
            <v-btn :disabled="!valid" color="green" dark @click="CapNhatCuoc" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale'">
              Đổi
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
      <Loading :status="loaderCuoc" />
    </v-dialog>
  </div>
</template>

<script>
import Query from '@/plugins/query/global'
import { Request } from '@/plugins/helper/fetch'
import { staff } from '@/plugins/helper/status'
import { Status } from '@/plugins/helper/dstatus'
import validations from '@/plugins/helper/validations'
import {mapGetters } from 'vuex'
import Add from '@/components/staff/add.vue'
import Manage from '@/components/base/thaotac.vue'
import Dialog from '@/components/base/Dialog.vue'
import Loading from '@/components/base/Loading.vue'
import {DoiCuoc} from "../plugins/helper/tinhtien";
export default {
  name: 'staff',
  components: {
    Add,
    Manage,
    Dialog,
    Loading,
  },
  data () {
    return {
      Locked: false,
			UpdateData:{
				AuctionFee: null,
				JPY: 0,
			},
			Selected:[],
			FeeData: {},
      notice: new Status(this.$swal),
      status: {
        ...staff
      },
      closed: false,
      NewPassword: '',
      CuocID: undefined,
      Cuoc: 0,
      CuocSea: 0,
      valid:false,
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      search: '',
      req: new Request('staff'),
      items: [],
      boxAdd: false,
      boxCuoc: false,
      loaderCuoc: false,
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    DanhSachMoi: function(){
        return this.items.map(
          (items, index) => ({
            ...items,
            index: index + 1,
						TopSale: items['TopSale'] ? items['TopSale']: false,
          }))
      },
		ListSale(){
			return [{_id:false,Username:'Chưa có Quản lý'}].concat(this.DanhSachMoi.filter(v=>v.Permision === 'sale'));
		},
		headers(){
			let headers = [
				{ text: '#', value: 'index', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
				{ text: 'Tên', value: 'Name', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
				{ text: 'Tài khoản', value: 'Username', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
				{ text: 'Đối tác', value: 'PartnerData', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
				{ text: 'Cước', value: 'Shipment', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'C.Khấu kho', value: 'ChietKhau', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
				{ text: 'Nhóm', value: 'Permision', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
				// { text: 'Thao tác', value: '_id', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
			];
			if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager'){
				headers.push({ text: 'Quản lý bởi', value: 'TopSale', align: 'center',width: "150px",class:"grey lighten-2 black--text" });
			}
			if(this.loginInfo._perm === 'admin'){
				headers.push({ text: 'Quyền', value: 'Rules', align: 'center',width: "100px",class:"grey lighten-2 black--text" });
			}
			return headers;
		}
  },
  methods: {
    ...Query,
    ...validations,
		DoiCuoc,
    doChangeAccountStatus(item){
      this.loading = true;
      if(item.Locked){
        return this.req.patch('unlock',{Staff: item._id}).then(async resp=>{
          const json = await resp.json()
          if(json.success === true){
            this.notice.success("Đã mở khóa tài khoản !!!");
          }else{
            const msg = json.error || "Không thể cập nhật dữ liệu !!!"
            this.notice.error(msg);
          }
        })
        .catch(err=>{
          this.notice.error(err);
        })
        .finally(()=>{
          this.loading = false;
          this.LayDuLieu();
        });
      }else{
        return this.req.patch('lock',{Staff: item._id}).then(async resp=>{
          const json = await resp.json()
          if(json.success === true){
            this.notice.success("Đã khóa tài khoản !!!");
          }else{
            const msg = json.error || "Không thể cập nhật dữ liệu !!!"
            this.notice.error(msg);
          }
        })
        .catch(err=>{
          this.notice.error(err);
        })
        .finally(()=>{
          this.loading = false;
          this.LayDuLieu();
        });
      }
    },
		doUpdateAuction(){
			const {UpdateData, Selected} = this;
			let data = {
				AuctionFee: UpdateData.AuctionFee,
				JPY: UpdateData.JPY,
				Staff: Selected.map(item=>item['_id']),
			}
			this.req.patch('doifee',data).then(async resp=>{
				const json = await resp.json()
				if(json.success === true){
					this.notice.success("Đã cập nhật dữ liệu !!!");
					this.Selected = [];
					this.closed = true;
					this.UpdateData = {
						AuctionFee: null,
						JPY: 0,
					}
				}else{
					const msg = json.error || "Không thể cập nhật dữ liệu !!!"
					this.notice.error(msg);
				}
			})
			.catch(err=>{
				this.notice.error(err);
			})
			.finally(()=>{
				this.loading = false;
				this.LayDuLieu();
			})
		},
		doUpdateRules(item,rule){
			const {_id} = item;
			const Rule = item['Rules'][rule];
			const Query = {
				[rule]: !Rule
			}
			this.loading = true;
			this.req.patch('rules/'+_id,Query).then(async resp=>{
				const json = await resp.json()
				if(json.success === true){
					this.notice.success("Đã cập nhật dữ liệu !!!");
				}else{
					const msg = json.error || "Không thể cập nhật dữ liệu !!!"
					this.notice.error(msg);
				}
			})
				.catch(err=>{
					this.notice.error(err);
				})
				.finally(()=>{
					this.loading = false;
					this.LayDuLieu();
				})
		},
		doUpdateLimited(item){
			const {_id,Limited} = item;
			this.loading = true;
			this.req.patch('limited/'+_id,{Limited:!Limited}).then(async resp=>{
				const json = await resp.json()
				if(json.success === true){
					this.notice.success("Đã cập nhật dữ liệu !!!");
				}else{
					const msg = json.error || "Không thể cập nhật dữ liệu !!!"
					this.notice.error(msg);
				}
			})
				.catch(err=>{
					this.notice.error(err);
				})
				.finally(()=>{
					this.loading = false;
					this.LayDuLieu();
				})
		},
		doUpdateTopSale(item){
			const {_id,TopSale} = item;
			this.loading = true;
			this.req.patch('topsale/'+_id,{Sale:TopSale}).then(async resp=>{
				const json = await resp.json()
				if(json.success === true){
					this.notice.success("Đã cập nhật dữ liệu !!!");
				}else{
					const msg = json.error || "Không thể cập nhật dữ liệu !!!"
					this.notice.error(msg);
				}
			})
			.catch(err=>{
				this.notice.error(err);
			})
			.finally(()=>{
				this.loading = false;
				this.LayDuLieu();
			})
		},
    toggleDoiCuoc(item,type = 'doicuoc'){
			if(type === 'doicuoc') {
				this.FeeData = {
					Body:{Shipment:item.Shipment,ShipmentSea:item.ShipmentSea},
					ID: item._id,
					Query: type,
          Title: `Đổi cước v/chuyển`
				}
			}else if(type === 'doifee'){
				this.FeeData = {
					Body:{JPY:item.JPY,AuctionFee:item.AuctionFee},
					ID: item._id,
					Query: type,
          Title: `Đổi tỉ giá - đấu giá`
				}
			}else if(type === 'doick'){
        this.FeeData = {
          Body:{CKA: item.CKA, CKB: item.CKB},
          ID: item._id,
          Query: type,
          Title: `Đổi % chiết khấu`
        }
      }
			this.boxCuoc = true;
		},
    CapNhatMatKhau(id){
      this.req.update(id,{Password:this.NewPassword}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi mật khẩu nhân viên thành công !!!")
          this.closed = true
          this.NewPassword = ''
        }else{
          const e = json.error || "Không đổi mật khẩu nhân viên !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    CapNhatCuoc(){
      this.loaderCuoc = true;
			const {FeeData} = this;
      this.req.patch(`${FeeData.Query}/${FeeData.ID}`,FeeData.Body).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.boxCuoc = false;
          this.Cuoc = 0;
          this.CuocSea = 0;
          this.notice.success("Đã đổi cước thành công !!!")
          this.LayDuLieu();
        }else{
          const e = json.error || "Không đổi được cước !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loaderCuoc = false;
      })
    },
    CustomControls(item){
      //{color:'red',text:'Xóa',do:this.Xoa}
      let Controls = []
      // if(item.Count > 0)
      // {
      //   Controls.splice(0,1)
      // }
      // if(this.loginInfo._perm === 'admin'){
      //   if(item.Status !=='dang_kiem_tra') Controls.splice(1,0,{color:'blue',text:'Chế độ kiểm tra',do:this.CheckMode})
      //   if(item.Status !=='dang_xu_ly') Controls.splice(1,0,{color:'green',text:'Chế độ xử lí',do:this.OpenMode})
      //   if(item.Status !=='khoa') Controls.splice(1,0,{color:'orange',text:'Chế độ khóa',do:this.LockMode})
      // }
      if(item){
        return Controls 
      }
      return Controls
    },
    changeMode(){
      this.loading = true
      const p = {...this.Change}
      this.req.update(p.ID,p.Update).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể cập nhật cho Shipment"
          this.notice.error(msg)
        }
      })
      .catch(err=>{
        this.notice.error(err)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    LayDuLieu() {
      const Request = this.Locked ? this.req.get('?Locked=true'):this.req.fetch();
      Request.then(async resp=>{
        const json = await resp.json()
        this.items = json.data
      })
      .catch(err=>{
        this.notice.error(err)
      })
    },
    Link(Code){
      return `/shipment/${Code}`
    },
    TimKiem() {

    },
    Them(data) {
      this.req.create(data).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.boxAdd = false
          this.notice.success("Đã thêm mới dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể thêm mới dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    }
  },
  mounted(){
    if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager' && this.loginInfo._perm !== 'sale'){
      this.$router.push('/')
    }
    this.loading = true
    this.Staff().then(resp=>{
      this.items = resp.data
    })
    .catch(e=>{
      this.notice.error(e)
    })
    .finally(()=>{
      this.loading = false
    })
  }
}
</script>
